import endsWith from 'lodash/endsWith'
import Api from './Api'

export default {
  base () {
    const api = Api()
    api.defaults.baseURL = process.env.VUE_APP_API_URL_IDUC
    return api
  },

  async login (email, password, keepSignIn) {
    return await this.base().post('/v1/login', {
      email: email,
      password: password,
      longLivedToken: keepSignIn
    })
  },

  async logout () {
    try {
      await this.base().delete('/v1/logout')
    } catch (error) {
      console.log('Failed to logout', error)
    }
    return true
  },

  helpers: {
    externalInstitutionalDomains: ['deq.uc.pt', 'dei.uc.pt', 'deec.uc.pt', 'mat.uc.pt'],

    isExternalInstitutionalEmail (email) {
      let notAllowedDomain = false
      this.externalInstitutionalDomains.forEach(domain => {
        if (endsWith(email, domain)) {
          notAllowedDomain = true
        }
      })

      if (notAllowedDomain) {
        return true
      } else {
        return false
      }
    },

    isInstitutionalEmail (email) {
      if (!this.isExternalInstitutionalEmail(email) && (endsWith(email, '@uc.pt') || endsWith(email, '.uc.pt'))) {
        return true
      } else {
        return false
      }
    },

    isInstitutionalEmailOnly (email) {
      if (!this.isExternalInstitutionalEmail(email) && endsWith(email, '@uc.pt')) {
        return true
      } else {
        return false
      }
    },

    isInstitutionalStudentEmail (email) {
      if (endsWith(email, '@student.uc.pt')) {
        return true
      } else {
        return false
      }
    }
  }
}
