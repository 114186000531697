<template>
  <section class="hero is-fullheight page-login">
    <div class="hero-body">
      <div class="block">
        <div class="logo mt-2 mb-6">
          <img alt="logo" src="../assets/logo.svg" />
        </div>
        <form
          class=""
          method="post"
          @keypress.enter="doLogin"
          @submit.prevent="doLogin"
        >
          <b-field
            label="Email institucional"
            label-for="email"
            :type="{ 'is-danger': $v.email.$error }"
            :message="$v.email.$error ? $t('message.form.emailRequired') : ''"
          >
            <b-input v-model="email" type="email"></b-input>
          </b-field>
          <b-field
            label="Password"
            label-for="password"
            :type="{ 'is-danger': $v.password.$error }"
            :message="
              $v.password.$error ? $t('message.form.passwordRequired') : ''
            "
          >
            <b-input v-model="password" type="password" name="password" />
          </b-field>
          <b-message v-if="loginErrors" type="is-danger">
            <div v-for="error in loginErrors" :key="error.key">
              <div v-if="error.key == 'MaxAttempts'">
                <p>
                  Foi ultrapassado o limite de tentativas. Por favor, tente mais
                  tarde.
                </p>
              </div>
              <div v-else-if="error.key == 'InvalidUCEmailOrPassword'">
                <p class="has-text-weight-bold mb-1">
                  O seu email institucional ou password não estão corretos.
                </p>
                <a href="https://apps.uc.pt/recover" class="is-size-7 has-text-weight-bold"
                  >Esqueceu-se da password?</a
                >
              </div>
              <div v-else>
                <div>
                  Não foi possível autenticar.<br />Por favor, tente mais tarde.
                </div>
                <div class="has-text-tiny has-margin-top-small">
                  Error ID: {{ error.key }}
                </div>
              </div>
              <div class="mt-4 is-size-7">
                Se precisar de ajuda, por favor, envie-nos uma mensagem para
                <strong>support@ucframework.pt</strong>
              </div>
            </div>
          </b-message>
          <b-field>
            <b-button
              expanded
              :disabled="!isAllowedEmail"
              :type="isAllowedEmail ? 'is-primary' : 'is-dark'"
              @click="doLogin"
              >{{ $t("login") }}</b-button
            >
          </b-field>
          <b-field>
            <b-checkbox v-model="keepSignIn">Manter sessão ativa</b-checkbox>
          </b-field>
        </form>
        <div class="mt-5">
          <b-button @click="$router.push('/')" type="is-light" expanded size="is-small">{{ $t("cancel") }}</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import ServiceAuth from '@/services/ServiceAuth'

Vue.use(Vuelidate)

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      keepSignIn: false,
      loginErrors: '',
      isEmailNotAllowed: false
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
    password: { required, min: minLength(1) },
    keepSignIn: false
  },

  computed: {
    isAllowedEmail () {
      return ServiceAuth.helpers.isInstitutionalEmail(this.email)
    }
  },

  created () {
    this.$store.dispatch('logout')
    setTimeout(() => {
      this.isEmailNotAllowed = !this.isAllowedEmail
    }, 1000)
  },

  methods: {
    async doLogin () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const response = await ServiceAuth.login(this.email, this.password, this.keepSignIn)
          this.$store.dispatch('setSession', { session: response.data })
          axios.defaults.headers.common.Authorization = response.data.token
          this.$router.push(this.$route.query.redirect || '/')
        } catch (error) {
          this.loginErrors = error.response.data.__errors__
        }
      }
    }
  }
}
</script>
